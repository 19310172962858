import { Body } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import React from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"

import { getReviews } from "."

import { reviewCard, verifiedChip } from "./styles.css"

type ReviewCardProps = {
  className?: string
  review: ReturnType<typeof getReviews>[number]
}

export const ReviewCard: React.FC<ReviewCardProps> = ({ className, review }) => {
  const { t } = useTranslation()
  return (
    <div className={`${reviewCard} ${className ? className : ""}`}>
      <div className={verifiedChip}>
        <MyImage alt="verified icon" height={13} src="/images/home/google-reviews/verified.svg" width={13} />
        <Body noMargin size="xs">
          {t("sell:reviews_cards_badge")}
        </Body>
      </div>
      <Body noMargin html={review.content} size="m" />
      <Body noMargin html={review.name} size="m" />
    </div>
  )
}
