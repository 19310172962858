import { Body, H2, vars } from "@casavo/habitat"
import { motion } from "framer-motion"
import { Translate } from "next-translate"
import useTranslation from "next-translate/useTranslation"
import React, { useCallback, useRef, useState } from "react"

import { Accordion } from "@app/shared/components/Accordion"
import { MyImage } from "@app/shared/components/MyImage"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { useFadeInTransition } from "@app/shared/hooks/useFadeInTransition"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import {
  faqSection,
  faqDesktopContent,
  faqWrapper,
  questions,
  question,
  answers,
  faqMobileContent,
  customAccordion,
  topLeftCloud,
  bottomRightCloud,
} from "./styles.css"
type FaqProps = {}

const getFAQData = (t: Translate) => [
  {
    answer: t("sell:faqs_answer_01_body"),
    question: t("sell:faqs_question_01_title"),
  },
  {
    answer: t("sell:faqs_answer_02_body"),
    question: t("sell:faqs_question_02_title"),
  },
  {
    answer: t("sell:faqs_answer_03_body"),
    question: t("sell:faqs_question_03_title"),
  },
]

export const Faq: React.FC<FaqProps> = () => {
  const [activeQuestion, setActiveQuestion] = useState(0)
  const { t } = useTranslation()
  const FAQ = getFAQData(t)

  const targetRef = useRef(null)
  const { opacity, y } = useFadeInTransition({ targetRef })

  useChangeBgColorInView(targetRef, vars.colors.background.secondary.light, { amount: 0.5 })

  const handleQuestionClick = useCallback((idx: number) => {
    handleUserEventClick(`ClickAccordion-Question0${idx + 1}`, "Homepage")
    setActiveQuestion(idx)
  }, [])

  return (
    <motion.section ref={targetRef} className={faqSection} style={{ opacity, y }}>
      <MyImage alt="" className={topLeftCloud} height={135} src="/images/home/faq/cloud.svg" width={256} />
      <MyImage
        alt=""
        className={bottomRightCloud}
        height={135}
        src="/images/home/faq/cloud.svg"
        width={256}
      />
      <div className={faqWrapper}>
        <H2 noMargin>{t("sell:faqs_header")}</H2>
        <div className={faqDesktopContent}>
          <div className={questions}>
            {FAQ.map((q, idx) => (
              <button
                key={q.question}
                className={`${question} ${activeQuestion === idx ? "active" : ""}`}
                onClick={() => {
                  handleQuestionClick(idx)
                }}
              >
                <Body noMargin strong size="m">
                  {q.question}
                </Body>
              </button>
            ))}
          </div>
          <div className={answers}>
            <Body noMargin html={FAQ.at(activeQuestion)?.answer} size="l" />
          </div>
        </div>
        <div className={faqMobileContent}>
          {FAQ.map((q, idx) => (
            <Accordion
              key={q.question}
              className={`${customAccordion} ${activeQuestion === idx ? "active" : ""}`}
              isOpen={activeQuestion === idx}
              title={q.question}
              onToggle={() => {
                setActiveQuestion(idx)
              }}
            >
              <Body html={q.answer} />
            </Accordion>
          ))}
        </div>
      </div>
    </motion.section>
  )
}
