import Script from "next/script"
import useTranslation from "next-translate/useTranslation"
import { useInView } from "react-intersection-observer"

import { BgColorProvider } from "@app/shared/utils/context/useBgColor"
import { useSaveMarketingCampaignParams } from "@app/shared/utils/marketingServerToServer"

import { HomeCards } from "./Cards"
import { HomeHero } from "./Hero"
import { NewHomePage } from "./NewHomePage"
import { HomePillars } from "./Pillars"
import { HomePress } from "./Press"
import { Steps } from "./Steps"
import { HomeStickyCta } from "./StickyCta"
import { greenArea, homeSeparator, transparentArea } from "./styles.css"

interface HomeContentProps {
  heroIsVisible: boolean
  updateHeroVisibility: (visibility: boolean) => void
}

export const HomeContent: React.FC<HomeContentProps> = ({ heroIsVisible, updateHeroVisibility }) => {
  const { t } = useTranslation("sell")
  const { inView, ref } = useInView({
    rootMargin: "0px 0px -120px 0px",
  })
  const { lang } = useTranslation()

  useSaveMarketingCampaignParams()

  return (
    <>
      {["fr"].includes(lang) && (
        <>
          <Script
            async
            src="//pixel.mathtag.com/event/js?mt_id=1628525&mt_adid=260491&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3="
          />
          <HomeHero updateVisibility={updateHeroVisibility} />
          <hr className={homeSeparator} />
          <Steps />
          <HomePillars />
          <HomePress />
          <section ref={ref} className={inView ? greenArea : transparentArea}>
            <HomeCards />
          </section>
        </>
      )}
      {["it", "en"].includes(lang) && (
        <BgColorProvider>
          <NewHomePage updateHeroVisibility={updateHeroVisibility} />
        </BgColorProvider>
      )}
      <HomeStickyCta
        description={t("sticky.description")}
        gtmEvent={{ value: "StickyFooter" }}
        heroIsVisible={heroIsVisible}
        label={t("sticky.title")}
        url="#hero-section"
      />
    </>
  )
}
