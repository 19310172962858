import { Body, Button, H1, H2, vars } from "@casavo/habitat"
import { Description, Title } from "@radix-ui/react-dialog"
import { motion } from "framer-motion"
import useTranslation from "next-translate/useTranslation"
import { useRef, useState } from "react"

import { Drawer } from "@app/shared/components/Drawer"
import { ArrowLeft } from "@app/shared/components/icons/ArrowLeft"
import { CircleCheck } from "@app/shared/components/icons/CircleCheck"
import { Close } from "@app/shared/components/icons/Close"
import { MyImage } from "@app/shared/components/MyImage"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { useFadeInTransition } from "@app/shared/hooks/useFadeInTransition"
import useIsDesktop from "@app/shared/hooks/useIsDesktop"
import { getMarketingCampaignQueryParams } from "@app/shared/utils/marketingServerToServer"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { Card } from "../Card"
import { firstCardItems, getDrawerServiceList, secondCardItems } from "../data"

import {
  cardWrapper,
  closeDrawerButton,
  content,
  drawerBody,
  drawerContent,
  drawerCta,
  drawerHeader,
  drawerImage,
  drawerService,
  drawerServiceItem,
} from "./styles.css"

export const ValuePropositionSection: React.FC = () => {
  const { lang, t } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const DRAWER_SERVICE_LIST = getDrawerServiceList(t)
  const gtmEventLabel = "HomePage_V.01_2024"
  const targetRef = useRef(null)
  const { opacity, y } = useFadeInTransition({ targetRef })
  const isDesktop = useIsDesktop()
  useChangeBgColorInView(targetRef, vars.colors.background.default.dark, { amount: 0.5 })

  const getValuationFlowUrl = (lang: string): string => {
    const queryParams = new URLSearchParams({
      ...getMarketingCampaignQueryParams(),
      traffic_source: "wine",
    })

    const segment = lang === "it" ? "it/offerta-gratuita/1/1" : "/get-free-offer/1/1"
    return `${process.env.NEXT_PUBLIC_FLOW_DOMAIN}/${segment}/?${queryParams.toString()}`
  }

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open)
  }

  const redirectToValuationFlow = () => {
    window.location.href = getValuationFlowUrl(lang)
  }

  const handleFirstCardClick = () => {
    handleUserEventClick("ClickCard-StartValuation", gtmEventLabel)
    redirectToValuationFlow()
  }

  const handleSecondCardClick = () => {
    handleUserEventClick("ClickCard-FeesDeepDive", gtmEventLabel)
    toggleDrawer(true)
  }

  return (
    <>
      <div ref={targetRef}>
        <motion.div className={content} style={{ opacity, y }}>
          <div className={sprinkles({ textAlign: "left" })}>
            <H1 display>{t("sell:valueprop_header")}</H1>
          </div>

          <div className={cardWrapper}>
            <Card
              ctaLabel={t("sell:valueprop_card_evaluation_cta")}
              description={t("sell:valueprop_card_evaluation_body")}
              imageItems={firstCardItems(t, isDesktop)}
              title={t("sell:valueprop_card_evaluation_headline")}
              onClick={handleFirstCardClick}
            />
            <Card
              ctaLabel={t("sell:valueprop_card_fees_cta")}
              description={t("sell:valueprop_card_fees_body")}
              imageItems={secondCardItems(isDesktop)}
              title={t("sell:valueprop_card_fees_headline")}
              onClick={handleSecondCardClick}
            />
          </div>
        </motion.div>
      </div>
      <Drawer open={isDrawerOpen} onOpenChange={toggleDrawer}>
        <div className={drawerContent}>
          <div className={drawerHeader}>
            <button
              aria-label="Close drawer"
              className={closeDrawerButton}
              onClick={() => toggleDrawer(false)}
            >
              <ArrowLeft
                aria-hidden="true"
                className={sprinkles({ display: { desktop: "block", mobile: "none" } })}
                fill={vars.colors.root.neutral[400]}
                height={20}
                width={20}
              />
              <Close
                aria-hidden="true"
                className={sprinkles({ display: { desktop: "none" } })}
                fill={vars.colors.root.neutral[400]}
                height={24}
                width={24}
              />
            </button>
          </div>
          <div className={drawerBody}>
            <Title asChild>
              <span aria-level={2} role="heading">
                <H2>{t("sell:valueprop_modal_fees_headline")}</H2>
              </span>
            </Title>
            <MyImage
              alt=""
              className={drawerImage}
              height={189}
              src="/images/home/value-prop/drawer-image.webp"
              width={548}
            />

            {DRAWER_SERVICE_LIST.map((service) => (
              <Description key={service.title}>
                <Body strong size="l">
                  {service.title}
                </Body>
                <div className={drawerService}>
                  {service.items.map((item) => (
                    <div key={item} className={drawerServiceItem}>
                      <CircleCheck
                        checkColor={vars.colors.foreground.success.default}
                        circleColor={vars.colors.foreground.success.background}
                        height={24}
                        style={{ minWidth: 24 }}
                        width={24}
                      />
                      <Body noMargin size="m">
                        {item}
                      </Body>
                    </div>
                  ))}
                </div>
              </Description>
            ))}
          </div>

          <div className={drawerCta}>
            <Button onPress={redirectToValuationFlow}>{t("sell:valueprop_modal_fees_cta")}</Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}
