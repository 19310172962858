import dynamic from "next/dynamic"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { AddressAutocomplete } from "@app/shared/components/AddressAutocomplete"
import { MyImage } from "@app/shared/components/MyImage"
import { Typography } from "@app/shared/components/Typography"

import {
  homeHero,
  homeHeroAnimationDesktop,
  homeHeroAnimationMobile,
  homeHeroContent,
  homeHeroContentSubtitle,
  homeHeroContentTitle,
  homeHeroImageDesktop,
  homeHeroImageMobile,
  homeHeroImageWrapperDesktop,
  homeHeroImageWrapperMobile,
  homeHeroVideoDesktop,
  homeHeroVideoMobile,
} from "./styles.css"

const LottiePlayer = dynamic(() => import("@app/shared/components/LottiePlayer"))

export type HomeHeroProps = {
  updateVisibility: (visibility: boolean) => void
}

export const HomeHero: FC<HomeHeroProps> = ({ updateVisibility }) => {
  const { inView, ref } = useInView({
    initialInView: true,
  })

  useEffect(() => {
    updateVisibility(inView)
  }, [inView, updateVisibility])

  const { t } = useTranslation("sell")
  return (
    <section ref={ref} className={homeHero} id="hero-section">
      <div className={homeHeroContent}>
        <Typography className={homeHeroContentTitle} component="h1" variant="display">
          {t("sell:title")}
        </Typography>
        <Typography className={homeHeroContentSubtitle} component="h2" variant="h4">
          {t("sell:subtitle")}
        </Typography>
        <AddressAutocomplete />
      </div>

      <div className={homeHeroVideoMobile}>
        <div className={homeHeroImageWrapperMobile}>
          <MyImage
            alt="hero"
            className={homeHeroImageMobile}
            height={376}
            loading="lazy"
            src="/images/home/hero.webp"
            width={700}
          />
        </div>
        <LottiePlayer loop className={homeHeroAnimationMobile} name="home/hp_buyers_m" />
      </div>

      <div className={homeHeroVideoDesktop}>
        <div className={homeHeroImageWrapperDesktop}>
          <MyImage
            alt="hero"
            className={homeHeroImageDesktop}
            height={376}
            loading="lazy"
            src="/images/home/hero.webp"
            width={700}
          />
        </div>
        <LottiePlayer className={homeHeroAnimationDesktop} loop={false} name="home/hp_buyers_d" />
      </div>
    </section>
  )
}
