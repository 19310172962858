import { H2, Body, Button } from "@casavo/habitat"
import { MouseEventHandler, useState } from "react"

import { ArrowRight } from "@app/shared/components/icons/ArrowRight"
import { MyImage } from "@app/shared/components/MyImage"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import useIsDesktop from "@app/shared/hooks/useIsDesktop"

import { Badge } from "../Badge"
import { SellSmartCardItem } from "../types"

import { card, cardImageWrapper } from "./styles.css"

type Props = {
  ctaLabel: string
  description: string
  imageItems: SellSmartCardItem[]
  onClick: MouseEventHandler<HTMLDivElement>
  title: string
}

export const Card: React.FC<Props> = ({ ctaLabel, description, imageItems, onClick, title }) => {
  const isDesktop = useIsDesktop()
  const [loadedImages, setLoadedImages] = useState<boolean[]>(new Array(imageItems.length).fill(false))

  const handleImageLoad = (index: number) => {
    setLoadedImages((prevLoadedImages) => {
      const newLoadedImages = [...prevLoadedImages]
      newLoadedImages[index] = true
      return newLoadedImages
    })
  }

  return (
    <div className={card} role="button" tabIndex={0} onClick={onClick}>
      <div className={sprinkles({ paddingBottom: "s" })}>
        <H2 noMargin>{title}</H2>
      </div>
      <Body noMargin size={isDesktop ? "l" : "s"}>
        {description}
      </Body>
      {!isDesktop && (
        <Button
          icon={<ArrowRight />}
          iconLayout="dx"
          style={{ pointerEvents: "none", width: "100%" }}
          theme="dark"
          variant="tertiary"
        >
          {ctaLabel}
        </Button>
      )}
      <div className={cardImageWrapper}>
        {imageItems.map((image, index) => (
          <div key={image.imageName} className={image.className}>
            <MyImage
              alt={""}
              height={image.imageSize.height}
              src={`/images/home/value-prop/${image.imageName}.webp`}
              width={image.imageSize.width}
              onLoad={() => handleImageLoad(index)}
            />
            {image.badgeList && loadedImages[index] && <Badge badgeList={image.badgeList} />}
          </div>
        ))}
      </div>
      {isDesktop && (
        <Button
          icon={<ArrowRight fill="white" />}
          iconLayout="dx"
          style={{ pointerEvents: "none", width: "100%" }}
          theme="dark"
          variant="primary"
        >
          {ctaLabel}
        </Button>
      )}
    </div>
  )
}
